import { Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import '@fontsource/pacifico'
import '@fontsource/comfortaa'
import '@fontsource/nunito'
import '@fontsource/nunito/700.css'
import '@fontsource/nunito/900.css'
import { lazy, Suspense } from 'react'
const Home = lazy(() => import('./pages/Home'))
const Team = lazy(() => import('./pages/About'))
// const Login = lazy(() => import('./pages/Login'))
// const Dashboard = lazy(() => import('./pages/Dashboard'))

function App() {
	return (
		<div>
			<Routes>
				<Route
					path="/"
					element={
						<Suspense fallback={<>...</>}>
							<Home />
						</Suspense>
					}
				/>
				<Route
					path="about"
					element={
						<Suspense fallback={<>...</>}>
							<Team />
						</Suspense>
					}
				/>
				{/* <Route
					path="login"
					element={
						<Suspense fallback={<>...</>}>
							<Login />
						</Suspense>
					}
				/>
				<Route
					path="dashboard"
					element={
						<Suspense fallback={<>...</>}>
							<Dashboard />
						</Suspense>
					}
				/> */}
			</Routes>
		</div>
	)
}

export default App
